import { useSlots } from 'vue';

export const getDefaultSlot = () => {
  const slots = useSlots();

  if (typeof slots.default !== 'function') {
    return null;
  }

  return slots.default()[0];
};

export const getDefaultSlotText = () => {
  const slot = getDefaultSlot();

  return typeof slot?.children === 'string' ? slot.children : null;
};

export const getTypedKey = (item: object, key: any) => {
  return (item as Record<typeof key, string | number | symbol | undefined>)[key];
}

export const getTypedObject = <T extends object>(o: object) => o as T;
